import { Routes } from '@angular/router';
import { ContactPage, LoginAuthGuard, LoginOtpGuard, NonLoginAuthGuard } from './guard/auth.guard';
import { homeResolver, journeyResolver, profileResolver, roundResolver } from './resolver'


export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  },
  {
    path: '',
    loadComponent: () => import('./pages/pages.component').then(m => m.PagesComponent),
    canActivate: [LoginAuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'home',
        loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent),
        resolve: { data: homeResolver }
      },
      {
        path: 'courses1/:name/:hole',
        loadComponent: () => import('./pages/score-card-landing/score-card-landing.component').then(m => m.ScoreCardLandingComponent)
      },
      {
        path: 'courses/:name/:hole',
        loadComponent: () => import('./pages/course/course.component').then(m => m.CourseComponent)
      },
      {
        path: 'rounds',
        loadComponent: () => import('./pages/all-rounds/all-rounds.component').then(m => m.AllRoundsComponent)
      },  
      {
        path: 'round/:roundID',
        loadComponent: () => import('./pages/round/round.component').then(m => m.RoundComponent),
        resolve: { data: roundResolver }
      }
    ]
  },
  {
    path: 'login',
    loadComponent: () => import('./auth/login/login.component').then(m => m.LoginComponent),
    canActivate: [NonLoginAuthGuard]
  },
  {
    path: 'contact-support',
    loadComponent: () => import('./auth/contact-suport/contact-suport.component').then(m => m.ContactSuportComponent),
    canActivate: [ContactPage, NonLoginAuthGuard]
  },
  {
    path: 'support',
    loadComponent: () => import('./auth/suport/suport.component').then(m => m.SuportComponent),
    canActivate: [NonLoginAuthGuard]
  },
  {
    path: 'auth/:token',
    loadComponent: () => import('./auth/loginconnect/loginconnect.component').then(m => m.LoginconnectComponent),
    canActivate: [NonLoginAuthGuard]
  },
  {
    path: 'register',
    loadComponent: () => import('./auth/register/register.component').then(m => m.RegisterComponent),
    canActivate: [NonLoginAuthGuard]
  },
  {
    path: 'email-otp',
    loadComponent: () => import('./auth/email-otp/email-otp.component').then(m => m.EmailOtpComponent),
    canActivate: [LoginOtpGuard, NonLoginAuthGuard],
  },
  {
    path: '',
    loadComponent: () => import('./user/user.component').then(m => m.UserComponent),
    canActivate: [LoginAuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'profile',
        loadComponent: () => import('./user/profile/profile.component').then(m => m.ProfileComponent),
        resolve: { data: profileResolver }

      }
    ]
  },
  {
    path: 'journey/courses/:courseName/:hole/:shareID',
    redirectTo: (routeData) => {
      if (routeData.params['shareID'] === undefined) {
        return '/home'
      } else {
        return `post-round/golf-courses/${routeData.params['courseName']}/${routeData.params['hole']}/${routeData.params['shareID']}`
      }
    }
    // loadComponent: () => import('./journey/journey.component').then(m => m.JourneyComponent),
    // runGuardsAndResolvers: 'always',
    // resolve: { data: journeyResolver }
  },
  {
    path: 'journey/:Slug',
    redirectTo: (routeData) => {
      if (routeData.params['Slug'] === undefined) {
        return '/home'
      } else {
        return `post-round/courseName/${routeData.params['Slug']}`
      }      
    }
    // resolve: { data: journeyResolver },
    // loadComponent: () => import('./journey/journey.component').then(m => m.JourneyComponent),
    // runGuardsAndResolvers: 'always',
  },

  {
    path: 'post-round/golf-courses/:courseName/:hole/:shareID',
    loadComponent: () => import('./journey/journey.component').then(m => m.JourneyComponent),
    runGuardsAndResolvers: 'always',
    resolve: { data: journeyResolver }
  },
  {
    path: 'post-round/:courseName/:Slug',
    loadComponent: () => import('./journey/journey.component').then(m => m.JourneyComponent),
    runGuardsAndResolvers: 'always',
    resolve: { data: journeyResolver }
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
    runGuardsAndResolvers: 'always',
  },  
  {
    path: 'welcome',
    loadComponent: () => import('./pages/onboarding/onboarding.component').then(m => m.OnboardingComponent),
    runGuardsAndResolvers: 'always',
    canActivate: [LoginAuthGuard],
  },

  // {
  //   path: '',
  //   loadChildren: () => import('./journey/journey-routing.module').then(m => m.JourneyRoutingModule),
  //   runGuardsAndResolvers: 'always',
  // },

  // {
  //   path: '',
  //   loadChildren: () => import('./globe/globe-routing.module').then(m => m.GlobeRoutingModule),
  //   runGuardsAndResolvers: 'always',
  // },
  // {
  //   path: 'map',
  //   runGuardsAndResolvers: 'always',
  //   loadComponent: () => import('./map/map.component').then(m => m.MapComponent),
  // },

  // {
  //   path: 'globe',
  //   runGuardsAndResolvers: 'always',
  //   loadComponent: () => import('./globe/globe.component').then(m => m.GlobeComponent),
  // },
  // {
  //   path: 'test',
  //   runGuardsAndResolvers: 'always',
  //   loadComponent: () => import('./test/test.component').then(m => m.TestComponent),
  // },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/home'
  },
];
