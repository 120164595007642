import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, PLATFORM_ID, TransferState, WritableSignal, afterNextRender, effect, inject, isDevMode, makeStateKey, signal } from '@angular/core';
import { AlertService } from './services/alert.service';
import { ApiService } from './services/api.service';
import { EventService } from './services/event.service';
import { NavigationCancel, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, of, switchMap } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { DefaultComponent } from './modal/default/default.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@config';
import { AuthService } from './services/auth.service';
import { SubscriptionComponent } from './modal/subscription/subscription.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';



@Component({
  selector: 'bts',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, LoaderComponent, MatIconModule, OnboardingComponent]
})
export class AppComponent {
  protected display: WritableSignal<boolean> = signal(true);
  protected updateAvailable: WritableSignal<boolean> = signal(false);

  private event = inject(EventService);
  private api = inject(ApiService);
  private alertService = inject(AlertService);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private auth = inject(AuthService);

  private transferState = inject(TransferState)
  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);
  private swUpdate = inject(SwUpdate);

  protected onBoardingScreen: WritableSignal<'default' | 'onBoard' | ''> = signal('')

  constructor() {
    // this.api.post('/user/update-profile', { display_howto_banner: true   }).subscribe()
    // const _elementRef = inject(ElementRef);
    // _elementRef.nativeElement.removeAttribute("ng-version");
    // _elementRef.nativeElement.removeAttribute("ng-server-context");
    // this.subscriptionAlert()

    afterNextRender(() => {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate().then((e: boolean) => {
          this.updateAvailable.set(e);
        });
        this.swUpdate.versionUpdates.subscribe((res: any) => {
          res.type !== "VERSION_READY" && console.log("Site already Updated");
          res && res.type === "VERSION_READY" && res.currentVersion.hash !== res.latestVersion.hash && (this.updateAvailable.set(true))
        })
      }
    });

    // if (this.request) {
    //   const enToken =  this.request['token']
    //   const _user = enToken && this.storage.decryption(enToken, this.storage.USER.PASSWORD);
    //   // console.log(_user, '_user');
    //   this.api.TOKEN = _user.token
    // }

    // console.log('request', this.request.headers);
    // console.log('response', this.response);
    // console.log('response', this.storage.getDataField('token'));

    (isPlatformBrowser(this.platformId)) && (window.addEventListener("wheel", ((event: WheelEvent) => {
      event.ctrlKey && event.preventDefault();
    }), { passive: false }));



    // Event Service
    const isLoggedIn = toSignal(this.event.login, { initialValue: false });
    const _router = toSignal(this.router.events);

    const _userDetails = toSignal(this.event.userDetails);
    effect(() => {
      const router = _router();
      this.transferState.set(makeStateKey<boolean>('isLoggedIn'), isLoggedIn());
      // const _query = this.activeRoute.snapshot.queryParams;
      // const query = isEmpty(_query) ? '' : _query;
      // router instanceof NavigationStart && this.location.replaceState(this.location.path(), query.toString(), { ...this.state });
      if (router instanceof NavigationCancel && isPlatformBrowser(this.platformId) && !isLoggedIn()) this.router.navigate(['/login']);
      // if (router instanceof RouteConfigLoadEnd && isPlatformBrowser(this.platformId) && !isLoggedIn()) this.router.navigate(['/login']);
      if (router instanceof NavigationCancel && isPlatformBrowser(this.platformId) && isLoggedIn()) this.router.navigate(['/home']);
      if (router instanceof NavigationEnd && isPlatformBrowser(this.platformId) && !isDevMode()) this.api.getLocal(`/api/addToSiteMap?url=${environment.APP_URL}${this.router.url}`).subscribe(() => { })

    }, { allowSignalWrites: true });

    const userInit = toSignal(this.event.login.pipe(switchMap((login: any) => {
      if (!login) return of(false);
      const userDetails = _userDetails();
      this.userDataFetch();
      return of(login)
    })));
    userInit();
  }

  protected isOnline: boolean = true;
  @HostListener('window:offline') offline() {
    this.isOnline = false
  }
  @HostListener('window:online') online() {
    this.isOnline = true
  }
  private userDataFetch(): void {
    let subscriber: Subscription | undefined = this.api.get(`/user-details?timestamp=${new Date().getTime()}`).subscribe({
      next: (response: any) => {
        if (response && response.type == 'success') {
          // this.onBoardingScreen.set(response.data.display_howto_banner ? 'onBoard' : 'default')
          response.data.display_howto_banner && this.router.navigate(['/welcome']);
          // this.comingSoon();
          this.event.user.next(response.data);
        } else {
          if (!isPlatformBrowser(this.platformId)) this.auth.logout();
        }
      },
      error: (error: any) => {
        const err = error.cause.error;
        if (err.status === 426) {
          this.subscriptionAlert({ url: err.error.url, message: error.message, isLoggedIn: true })
        } else {
          this.alertService.alert(error.message, 'warning')
          if (err.status === 401) {
            this.auth.logout()
          }
        }

      },
      complete: () => { subscriber && (subscriber.unsubscribe(), subscriber = undefined) }
    })
  }
  responseOnBoard(e: any): void {
    console.log(e);
    
    if (e.onBoardClose) {
      const updateAPI: Subscription = this.api.post('/user/update-profile', { display_howto_banner: false }).subscribe({
        next: (response) => {
          if (response.type === 'success') {
            this.event.user.next(response.data);
            this.onBoardingScreen.set('default');
            
          }
        },
        error: (error: any) => {

        },
        complete: () => {
          updateAPI && updateAPI.unsubscribe()
        }
      });
    }
  }
  comingSoon() {
    // this.document.body.classList.add('coming-soon-modal');
    // this.dialog.open(DefaultComponent, {
    //   panelClass: 'custom-dialog-class2',
    //   data: {
    //     type: 'onboarding-screen',
    //   },
    // }).afterClosed().subscribe((response: any) => {
    //   this.document.body.classList.remove('coming-soon-modal');
    //   const updateAPI: Subscription = this.api.post('/user/update-profile', { display_howto_banner: false }).subscribe({
    //     next: (response) => {
    //       if (response.type === 'success') {
    //         this.event.user.next(response.data);
    //       }
    //     },
    //     error: (error: any) => {

    //     },
    //     complete: () => {
    //       updateAPI && updateAPI.unsubscribe()
    //     }
    //   });
    // });
  }

  activateUpdate(): void {
    this.swUpdate.activateUpdate().then((e) => {
      if (e) {
        this.updateAvailable.set(false);
        typeof this.document !== 'undefined' && this.document.location.reload()
      }
      setTimeout(() => {
        this.updateAvailable() && (this.updateAvailable.set(false));
        typeof this.document !== 'undefined' && this.document.location.reload()
      }, 2000)
    })
  }

  subscriptionAlert(data: { url: string, message: string, isLoggedIn: boolean }) {
    this.dialog.open(SubscriptionComponent, {
      panelClass: 'subscriptionPanel',
      data,
      disableClose: true
    })

  }
}
