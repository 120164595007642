import { ApplicationConfig, isDevMode, provideExperimentalZonelessChangeDetection, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withViewTransitions } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { HTTPInterceptor, TokenInterceptor } from './interceptors/interceptor.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }), withViewTransitions(), withComponentInputBinding()),
    provideClientHydration(withHttpTransferCacheOptions({})), // withEventReplay()
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptorsFromDi(), withInterceptors([HTTPInterceptor, TokenInterceptor])),
    // provideExperimentalZonelessChangeDetection(),
     //, withEventReplay()
     provideZoneChangeDetection({ eventCoalescing: true }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
        // {
    //   provide: HTTP_TRANSFER_CACHE_ORIGIN_MAP,
    //   useFactory: () => {
    //        return {
    //           ['http://localhost:4200']: ['https://api-connect-blue.igolf.com', 'https://elevation-imported-data.s3.amazonaws.com'],
    //       };
    //   }
    // }
  ]
};


