import { DOCUMENT, NgClass, isPlatformBrowser } from '@angular/common';
import { Component, OnDestroy, PLATFORM_ID, WritableSignal, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import Lottie from 'lottie-web';

import { EventService } from '@services';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [NgClass]
})
export class LoaderComponent implements OnDestroy {
  private lottieAnimation: WritableSignal<any> = signal(undefined);
  public isLoad: WritableSignal<boolean> = signal(true);

  private event = inject(EventService);
  private platformId = inject(PLATFORM_ID);
  private document = inject(DOCUMENT);


  constructor() {

    const _isHttpRequest = toSignal(this.event.isHttpRequest);
    const _isMapLoading = toSignal(this.event.isMapLoading);

    effect(() => {
      const isHttpRequest = _isHttpRequest() || _isMapLoading();
      typeof isHttpRequest === 'boolean' && (this.isLoad.set(isHttpRequest));
      isHttpRequest ? (() => {
        this.lottieAnimation() ? this.lottieAnimation().play() : this.setLoader();
      })() : this.lottieAnimation() && (this.lottieAnimation().stop())
    }, { allowSignalWrites: true });
  }

  private setLoader(): void {
    if (!isPlatformBrowser(this.platformId)) return
    const _doc: Element = this.document.getElementById('loader') as Element;
    if (!_doc) return (() => { setTimeout(() => { this.setLoader() }, 100) })();
    const lottieAnimation = Lottie.loadAnimation({
      container: _doc,
      renderer: 'svg', // You can use 'svg', 'canvas', or 'html' depending on your preference.
      loop: true,
      autoplay: true,
      path: './assets/cross.json'
      // Replace with the path to your Lottie animation JSON file.
    });

    this.lottieAnimation.set(lottieAnimation)
  }
  ngOnDestroy(): void {
    this.lottieAnimation() && this.lottieAnimation().destroy()
  }
}
