import { ResolveFn } from '@angular/router';
import { ApiService } from '@services';
import { inject } from '@angular/core';
import { map } from 'rxjs';

export const homeResolver: ResolveFn<any> = (route, state) => {
  const url: Array<string> = [`/user-device/list`];
  const api = inject(ApiService);
  const homeData = api.get(url[0]).pipe(map((response) => response))
  return homeData;
};
