import { Location } from '@angular/common';
import { TransferState, inject, makeStateKey } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { EventService, SharedService, StorageService } from '@services';

export const LoginAuthGuard: CanActivateFn = (route, state) => {
  const storage = inject(StorageService), event = inject(EventService), router = inject(Router);
  if(state.url.match('/round/')) {
    event.routeURL.set(state.url)
    // console.log(route, state.url);
    // inject(Location).replaceState(state.url, '', { data: state.url });
  }
  
  return storage.isAuthenticate();
  // return storage.isAuthenticate() ? true : inject(Router).createUrlTree(['/login']);
};

export const NonLoginAuthGuard: CanActivateFn = (route, state) => {
  const storage = inject(StorageService);
  return !storage.isAuthenticate();
};

export const LoginOtpGuard: CanActivateFn = (route, state) => {
  const storage = inject(StorageService);
  const shared = inject(SharedService);
  const isLoggedIn = storage.isAuthenticate()

  if (!isLoggedIn && shared.__authStep() === 1) return true
  return false;
};

export const ContactPage: CanActivateFn = (route, state) => {
  const storage = inject(StorageService);
  const shared = inject(SharedService);
  const isLoggedIn = storage.isAuthenticate()
  if (!isLoggedIn && shared.contactPage() === 1) return true
  return false;
};
