import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { map, zip } from 'rxjs';
import { ApiService } from '@services';
 
export const profileResolver: ResolveFn<any> = (route, state) => {
  const url: Array<string> = [`/golf/brand/list`, `/skill/list`];
  const api = inject(ApiService);
  const profileData = zip(api.get(url[0]), api.get(url[1]), (one, two) => { return { brand: one.data, skill: two.data }; }).pipe(map((response) => response));
  return profileData;
};
