import { HttpEvent, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AES, HmacSHA256, enc } from 'crypto-js';
import { tap } from 'rxjs';
import { EventService, StorageService } from '@services';
import { Location } from '@angular/common';
import { environment } from '@config';



export const HTTPInterceptor: HttpInterceptorFn = (req, next) => {
  const event = inject(EventService), requests: Array<HttpRequest<any>> = [];
  const removeRequest = (req: HttpRequest<any>) => {
    const i = event.requests.indexOf(req);
    if (i >= 0) event.requests.splice(i, 1);
    event.isHttpRequest.next(event.requests.length > 0);
  }
  req.headers.get('hasLoader') === 'true' && (event.requests.push(req), event.isHttpRequest.next(true));
  return next(req).pipe(tap({
    next: (event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) req.headers.get('hasLoader') === 'true' && removeRequest(req);
    },
    error: (error) => {
      req.headers.get('hasLoader') === 'true' && removeRequest(req);
    }
  }));
};

export const TokenInterceptor: HttpInterceptorFn = (req, next) => {
  const storage = inject(StorageService), authToken = storage.getDataField('token') || '', location = inject(Location);
  const split = environment.TOKEN_SPLIT, secret = environment.TOKEN_SECRET, key = environment.TOKEN_KEY, key_secret = environment.TOKEN_KEY_SECRET;
  const _P1 = HmacSHA256(key, key_secret).toString(enc.Base64url);
  const _P2 = new Date().toUTCString() + split + _P1, _S = AES.encrypt(_P2, secret).toString() || '';
  const authReq = req.clone({
    headers: req.headers.set('authorization', _S).set('x-access-token', authToken)
  });
  return next(authReq)
}