import { computed, inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { GolfRoundListTypeArray } from '@interfaces';
import { AlertService, ApiService, EventService } from '@services';
import { firstValueFrom } from 'rxjs';


export const roundResolver: ResolveFn<any> = async (route, state) => {
  const event = inject(EventService);
  const alert = inject(AlertService);
  const router = inject(Router)
  event.login.subscribe((res) => {
    console.log(res, 'res');

  })
  const api = inject(ApiService);
  const roundID = route.paramMap.get('roundID');

  if (!roundID) {
    throw new Error('No roundID provided');
  }

  let page: number = 1, pages: number = 2, total: number = 1;
  const limit = 20;
  let rounds: any[] = [];

  let distanceFactor = computed(() => {
    event.userDetails.subscribe((uData: any) => {
      if (Object.keys(uData).length) {
        return uData.customize_settings.metrics === 'meters' ? 1 : 1.0936
      } else {
        return 1.0936;
      }
    })
    return 1.0936;
  })


  // return
  while (true) {
    const fetchedRounds = await firstValueFrom(api.post('/golf-round/list', { page: page, limit }));
    console.log(fetchedRounds);
    page = fetchedRounds.data.page;
    pages = fetchedRounds.data.pages;
    total = fetchedRounds.data.total;
    fetchedRounds?.data?.docs.forEach((item: GolfRoundListTypeArray, index: number) => {
      item.roundNo = item.indexNo;
      item.checked = false;
      item.played_distance = Number((item.played_distance * distanceFactor()).toFixed(0));
      item.totalTboxDistance = Number((item.totalTboxDistance * distanceFactor()).toFixed(0));
    });

    rounds = rounds.concat(fetchedRounds.data.docs);

    // Check if the desired roundID is in the fetched rounds
    const foundRound = rounds.find(round => round._id === roundID);

    console.log(foundRound, rounds);

    if (foundRound) {
      event.specificRound.next({ data: foundRound, roundNo: foundRound.roundNo });
      event.courseDetails.next({ page: page, pages: pages, total: total, courseData: rounds });

      return foundRound; // Return the found round
    }

    // If we fetched fewer rounds than the limit, it means we've reached the end
    if (page >= pages) {
      alert.alert('Unauthorized Access', 'error');
      router.navigate(['/']);

      return
    }

    page += 1;

  }
};