import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { ApiService } from '@services';

export const journeyResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> => {
  const slug = route.params['Slug'], id = route.params['shareID'];
  let payload = ''
  slug && (payload = `slug=${slug}`);
  id && (payload = `id=${id}`);

  const url = `/round-share/meta?${payload}`;
  const api = inject(ApiService);
  const journeyData = api.get(url).pipe(map((response) => response), catchError((error) => { console.error('Error retrieving journey data:', error); return of(false); }));
  return journeyData;
};